/* -------------------------------------------

Name: 		ITSulu
Version:    1.0
Developer:	Nazar Miller (millerDigitalDesign)
Portfolio:  https://themeforest.net/user/millerdigitaldesign/portfolio?ref=MillerDigitalDesign

------------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Satisfy&family=Sora:wght@100..800&family=Syne:wght@400..800&display=swap");
@import 'variables';
@import 'plugins';
@import 'common';
@import 'components';
@import 'particles';
@import 'fa';
@import 'contact_form';
@import 'navigation';
@import 'posts';
