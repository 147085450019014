.partiles-parent {
  position: relative;

  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


button.partiles-parent {
  .particles {
    left: 5%;
    width: 90%;
  }
}
