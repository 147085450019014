.mil-top-panel-transparent {
  search {
    .input-group-text, .form-control, results, .list-group, .list-group-item {
      border-color: $lt-40;
      background-color: $dt-30;
      color: white;
      &::placeholder {
        color: $lt-40;
      }

      &.list-group-item-action:hover {
        background-color: $lt-10;
      }
    }
  }
}
