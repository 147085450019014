.fade-transition {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity 0.3s;
}

.fade-transition.show {
  opacity: 1;
  visibility: visible;
  height: auto;
}
