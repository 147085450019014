// Allied Code colors - TODO: integrate them into the theme
$dark-blue              : #0C395B;
$cardinal               : #CC2329;
$curious-blue           : #288ACF;
$lilly-white            : #EAF9FE;
$downy-green            : #74CBB4;
$medium-sky-blue        : #549EDF;

// Images for _common and _components scss template files
$logo-light: "/images/logo/logo-light.png";
$logo-dark: "/images/logo/logo-dark.png";
$deco-1: "/images/deco/deco-1.svg";
$deco-2: "/images/deco/deco-2.svg";

$font-1: 'Syne', sans-serif;
$font-2: 'Sora', sans-serif;
$font-3: 'Satisfy', cursive;

$dark: rgba(18, 24, 32, 1);
$light: rgba(255, 255, 255, 1);
$accent: $cardinal;

$accent-5: rgba($cardinal, .05);
$accent-10: rgba($cardinal, .1);
$accent-20: rgba($cardinal, .2);
$accent-30: rgba($cardinal, .3);
$accent-40: rgba($cardinal, .4);
$accent-50: rgba($cardinal, .5);
$accent-60: rgba($cardinal, .6);
$accent-70: rgba($cardinal, .7);
$accent-80: rgba($cardinal, .8);
$accent-90: rgba($cardinal, .9);
$accent-95: rgba($cardinal, .95);

$dt-5: rgba(18, 24, 32, .05);
$dt-10: rgba(18, 24, 32, .1);
$dt-20: rgba(18, 24, 32, .2);
$dt-30: rgba(18, 24, 32, .3);
$dt-40: rgba(18, 24, 32, .4);
$dt-50: rgba(18, 24, 32, .5);
$dt-60: rgba(18, 24, 32, .6);
$dt-70: rgba(18, 24, 32, .7);
$dt-80: rgba(18, 24, 32, .8);
$dt-90: rgba(18, 24, 32, .9);
$dt-95: rgba(18, 24, 32, .95);

$lt-5: rgba(255, 255, 255, .05);
$lt-10: rgba(255, 255, 255, .1);
$lt-20: rgba(255, 255, 255, .2);
$lt-30: rgba(255, 255, 255, .3);
$lt-40: rgba(255, 255, 255, .4);
$lt-50: rgba(255, 255, 255, .5);
$lt-60: rgba(255, 255, 255, .6);
$lt-70: rgba(255, 255, 255, .7);
$lt-80: rgba(255, 255, 255, .8);
$lt-90: rgba(255, 255, 255, .9);
$lt-95: rgba(255, 255, 255, .95);

$h1: 60px;
$h2: 42px;
$h3: 26px;
$h4: 22px;
$h5: 18px;
$h6: 16px;

$text: 15px;

$t-sm: .2s cubic-bezier(0, 0, 0.3642, 1);
$t-md: .4s cubic-bezier(0, 0, 0.3642, 1);
$t-lg: .6s cubic-bezier(0, 0, 0.3642, 1);
