// Enable callouts in markdown
// e.g.
//
//      ## This is a *left justified callout*
//
//      {: .right.quote}
//      ## This is a *quoted right justified callout*
//
section.post, section.update {
  a {
    color: $accent;
    text-decoration: underline;
  }

  em {
    color: $accent-60;
  }

  h2, h3 {
    margin-bottom: 10px !important;

    &.right {
      text-align: right;
      text-indent: 0;
    }

    em {
      color: $accent;
      font-style: normal;
    }

    &.quote {
      text-indent: -1.0em;

      &::before {
        content: open-quote;
        color: $accent;
        font-size: 150%;
        quotes: "“" "”";
        margin-right: 10px;
      }

      &::after {
        content: close-quote;
        color: $dt-30;
        font-size: 150%;
        quotes: "“" "”";
        margin-right: 10px;
      }
    }
  }
}
